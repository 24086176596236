<template>
  <v-dialog v-model="detalhesDialogVisible" max-width="600">
    <v-card>
      <v-card-title class="headline">Detalhes do Ativo</v-card-title>
      <v-card-text>
        <div><strong>Código:</strong> {{ selectedItem.codigo }}</div>
        <div><strong>Nome:</strong> {{ selectedItem.descricao }}</div>
        <div v-if="permissoes.includes(85)"><strong>Valor:</strong> {{ formatarValorReal(selectedItem.valor) }}</div>
        <div><strong>Unidade:</strong> {{ selectedItem.unidade_nome }}</div>
        <div><strong>Marca:</strong> {{ selectedItem.marca }}</div>
        <div><strong>Modelo:</strong> {{ selectedItem.modelo }}</div>
        <div><strong>IMEI:</strong> {{ selectedItem.imei }}</div>
        <div><strong>Código de Barras:</strong> {{ selectedItem.codigoBarras }}</div>
        <div><strong>Número de Série:</strong> {{ selectedItem.nrSerie }}</div>
        <div><strong>Fornecedor:</strong> {{ selectedItem.fornecedor_nome }}</div>
        <div><strong>Vida Útil:</strong> {{ selectedItem.vidaUtil }}</div>
        <div><strong>Data Fim da Garantia:</strong> {{ selectedItem.dtFinalGarantia }}</div>
        <div><strong>Complemento:</strong> {{ selectedItem.complemento }}</div>

        <div><strong>Anexos:</strong></div>
        <ul>
          <li v-for="anexo in selectedItem.anexos" :key="anexo.id">
            <a :href="getAnexoUrl(anexo.path)" target="_blank">{{ anexo.nome }}</a>
          </li>
        </ul>

        <div class="alinha-v alinha-centro">
          <img v-if="qrCodeUrl" :src="qrCodeUrl" alt="QR Code" />
        </div>
      </v-card-text>
      <div class="alinha-v alinha-centro">
        <button class="acao-secundaria" @click="detalhesDialogVisible = false">Fechar</button>
      </div>
      <br />
    </v-card>
  </v-dialog>

  <v-snackbar v-model="snackbarVisible" :color="snackbarColor" timeout="3000">
    {{ snackbarText }}
  </v-snackbar>

  <div class="titulo">
    <div class="margem container">
      <h2>Patrimônio</h2>
    </div>
  </div>

  <div class="margem container">
    <div class="bloco margem">
      <div class="linha">
        <div class="grid-4">
          <div>
            <label for="termo">Termo</label>
            <input type="text" v-model="termo" id="termo" />
          </div>

          <div>
            <label for="codigo">Código</label>
            <input type="text" v-model="codigo" id="codigo" />
          </div>

          <div>
            <label for="subCategoria">Tipo</label>
            <select v-model="subCategoria" id="subCategoria">
              <option value="" disabled selected>Selecione</option>
              <option v-for="item in categoriaLista" :key="item.id" :value="item.id">
                {{ item.displayName }}
              </option>
            </select>
          </div>

          <div>
            <label for="responsavel_id">Responsável</label>
            <select v-model="responsavel_id" id="responsavel_id">
              <option value="" disabled selected>Selecione</option>
              <option v-for="item in responsavelLista" :key="item.id" :value="item.id">
                {{ item.name }}
              </option>
            </select>
          </div>

          <div>
            <label for="dtInicio">De</label>
            <input type="date" v-model="dtInicio" id="dtInicio" />
          </div>

          <div>
            <label for="dtFim">Até</label>
            <input type="date" v-model="dtFim" id="dtFim" />
          </div>

          <div v-if="permissoes.includes(85)">
            <label for="valorInicial">Valor Inicial</label>
            <input type="text" v-model="valorInicial" id="valorInicial" @input="formatarValor($event, 'valorInicial')" @blur="formatarValorBlur('valorInicial')" />
          </div>

          <div v-if="permissoes.includes(85)">
            <label for="valorFim">Valor Final</label>
            <input type="text" v-model="valorFim" id="valorFim" @input="formatarValor($event, 'valorFim')" @blur="formatarValorBlur('valorFim')" />
          </div>

          <div>
            <label for="localFisico_id">Local Físico</label>
            <select v-model="localFisico_id" id="localFisico_id">
              <option value="" disabled selected>Selecione</option>
              <option v-for="item in localFisicoLista" :key="item.id" :value="item.id">
                {{ item.nome }}
              </option>
            </select>
          </div>

          <div>
            <label for="unidade">Unidade</label>
            <select v-model="unidade" id="unidade">
              <option value="" disabled selected>Selecione</option>
              <option v-for="item in filiais" :key="item.id" :value="item.id">
                {{ item.local_nome }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="linha direita">
        <div class="">
          <button style="margin-right: 10px" @click="limparCampos">Limpar</button>
          <button @click="buscar">Buscar</button>
        </div>
      </div>
      <div v-if="dadosRelatorio.length">
        <table class="tabela">
          <thead>
            <tr>
              <th class="text-center">Código</th>
              <th class="text-center">Data de Aquisição</th>
              <th class="text-center">Descrição</th>
              <th v-if="permissoes.includes(85)" class="text-center">Valor</th>
              <th class="text-center">Categoria</th>
              <th class="text-center">Responsável</th>
              <th class="text-center">Unidade</th>
              <th class="text-center">Local Físico</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in paginatedData" :key="item.codigo" @click="mostrarDetalhes(item)">
              <td class="text-center">{{ item.codigo }}</td>
              <td class="text-center">{{ formatarDataSemHMS(item.dtAquisicao) }}</td>
              <td class="text-center">{{ item.descricao }}</td>
              <td v-if="permissoes.includes(85)" class="text-center">{{ formatarValorTabela(item.valor) }}</td>
              <td class="text-center">{{ item.categoria }}</td>
              <td class="text-center">{{ item.responsavel }}</td>
              <td class="text-center">{{ item.unidade }}</td>
              <td class="text-center">{{ item.localFisico }}</td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <button class="acao-secundaria" :disabled="currentPage === 1" @click="prevPage">Anterior</button>
          <span>Página {{ currentPage }} de {{ totalPages }}</span>
          <button class="acao-secundaria" :disabled="currentPage === totalPages" @click="nextPage">Próxima</button>
        </div>
        <div v-if="permissoes.includes(85)" class="alinha-direita">
          <button class="" @click="buscarComExportacao">Exportar para Excel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getlistarUsuarios, getCategoria, getUnidade, getLocalFisico, relatorioAtivo, relatorioExport } from "@/services/api";
import { formatarDataSemHMS, formatarValorReal } from "@/utils/funcoesUtils";
import { getPermissao } from "@/services/permissaoService";

export default {
  name: "HomePatrimonio",
  components: {},
  data() {
    return {
      detalhesDialogVisible: false,
      snackbarVisible: false,
      snackbarColor: "success",
      snackbarText: "",
      loading: false,
      codigo: "",
      dtInicio: "",
      dtFim: "",
      responsavel_id: "",
      responsavelLista: [],
      valorInicial: "",
      valorFim: "",
      subCategoria: "",
      categoriaLista: [],
      localFisico_id: "",
      localFisicoLista: [],
      unidade: "",
      filiais: [],
      termo: "",
      dadosRelatorio: [],
      currentPage: 1,
      itemsPerPage: 10,
      permissoes: [],
      selectedItem: null,
    };
  },
  computed: {
    qrCodeUrl() {
      return this.selectedItem ? this.selectedItem.qrcode_base64 : "";
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.dadosRelatorio.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.dadosRelatorio.length / this.itemsPerPage);
    },
  },
  mounted() {
    const showSnackbar = localStorage.getItem("showSnackbar") === "true";
    if (showSnackbar) {
      this.snackbarText = localStorage.getItem("snackbarMessage");
      this.snackbarColor = localStorage.getItem("snackbarColor");
      this.snackbarVisible = true;

      localStorage.removeItem("showSnackbar");
      localStorage.removeItem("snackbarMessage");
      localStorage.removeItem("snackbarColor");
    }

    this.getlistarUsuarios();
    this.getCategoria();
    this.getUnidade();
    this.getLocalFisico();
    this.carregarPermissoes();
  },
  methods: {
    formatarValorReal,
    mostrarDetalhes(item) {
      this.selectedItem = item;
      this.detalhesDialogVisible = true;
    },
    formatarDataSemHMS,
    async getlistarUsuarios() {
      try {
        const response = await getlistarUsuarios();
        this.responsavelLista = response.data;
      } catch (error) {
        console.error("Erro ao carregar ativos:", error);
      }
    },
    async carregarPermissoes() {
      this.permissoes = await getPermissao();
    },
    async getCategoria() {
      try {
        const response = await getCategoria();
        this.categorias = response.data;
        this.prepararCategorias();
      } catch (error) {
        console.error("Erro ao buscar categorias superiores:", error);
      }
    },
    prepararCategorias() {
      const items = [];
      this.categorias.forEach((categoria) => {
        items.push({
          id: categoria.id,
          displayName: categoria.nome,
          disabled: false,
        });
        categoria.subCategoria.forEach((sub) => {
          items.push({
            id: sub.id,
            displayName: `${sub.codigo} - ${sub.nome}`,
            disabled: false,
          });
        });
      });
      this.categoriaLista = items;
    },
    handleSubCategoriaChange(value) {
      const selected = this.categoriaLista.find((item) => item.id === value);
      if (selected && selected.disabled) {
        this.subCategoria = null;
      } else {
        this.subCategoria = value;
      }
    },
    subCategoriaValida(value) {
      const selected = this.categoriaLista.find((item) => item.id === value);
      if (selected && selected.disabled) {
        return "Seleção inválida";
      }
      return true;
    },
    async getUnidade() {
      try {
        const response = await getUnidade();
        this.filiais = response.data;
      } catch (error) {
        console.error("Erro ao buscar filiais:", error);
      }
    },
    async getLocalFisico() {
      try {
        const response = await getLocalFisico();
        this.localFisicoLista = response.data;
      } catch (error) {
        console.error("Erro ao buscar filiais:", error);
      }
    },
    async buscar(params = {}) {
      this.loading = true;
      try {
        let response;
        if (params.gerar && params.gerar === "xlsx") {
          response = await relatorioExport({
            codigo: this.codigo,
            dtInicio: this.dtInicio,
            dtFim: this.dtFim,
            responsavel_id: this.responsavel_id,
            valorInicial: this.limparFormato(this.valorInicial),
            valorFim: this.limparFormato(this.valorFim),
            categoria_id: this.subCategoria,
            localFisico_id: this.localFisico_id,
            unidade_id: this.unidade,
            termo: this.termo,
            ...params,
          });
          this.downloadFile(response.data, "relatorio.xlsx");
        } else {
          response = await relatorioAtivo({
            codigo: this.codigo,
            dtInicio: this.dtInicio,
            dtFim: this.dtFim,
            responsavel_id: this.responsavel_id,
            valorInicial: this.limparFormato(this.valorInicial),
            valorFim: this.limparFormato(this.valorFim),
            categoria_id: this.subCategoria,
            localFisico_id: this.localFisico_id,
            unidade_id: this.unidade,
            termo: this.termo,
          });
          this.dadosRelatorio = response.data;
          this.snackbarText = "Relatório gerado com sucesso!";
          this.snackbarColor = "success";
        }
      } catch (error) {
        console.error("Erro ao gerar relatório:", error);
        this.snackbarText = "Erro ao gerar relatório.";
        this.snackbarColor = "error";
      } finally {
        this.snackbarVisible = true;
        this.loading = false;
      }
    },
    async buscarComExportacao() {
      await this.buscar({ gerar: "xlsx" });
    },
    downloadFile(data, filename) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      window.URL.revokeObjectURL(link.href);
    },
    limparFormato(valor) {
      if (!valor) return "";
      return valor.replace(/[^\d,]/g, "").replace(",", ".");
    },
    formatarValor(event, campo) {
      const value = event.target.value.replace(/\D/g, "");
      const options = { style: "currency", currency: "BRL" };
      const formatted = new Intl.NumberFormat("pt-BR", options).format(value / 100);
      this[campo] = formatted;
    },
    formatarValorBlur(campo) {
      if (this[campo]) {
        const value = this[campo].replace(/\D/g, "");
        const options = { style: "currency", currency: "BRL" };
        this[campo] = new Intl.NumberFormat("pt-BR", options).format(value / 100);
      }
    },
    formatarValorTabela(valor) {
      const options = { style: "currency", currency: "BRL" };
      return new Intl.NumberFormat("pt-BR", options).format(valor);
    },
    limparCampos() {
      this.codigo = "";
      this.dtInicio = "";
      this.dtFim = "";
      this.responsavel_id = "";
      this.valorInicial = "";
      this.valorFim = "";
      this.subCategoria = "";
      this.localFisico_id = "";
      this.unidade = "";
      this.termo = "";
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
  },
};
</script>

<style scoped>
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 5px;
}
</style>
