<template>
  <div class="titulo">
    <div class="margem container">
      <h2>{{ modoCadastro ? "Cadastrar" : "Editar" }} Ativo</h2>
    </div>
  </div>

  <form ref="formulario">
    <div class="margem container">
      <div class="bloco margem">
        <div class="titulo"><h3>Informações</h3></div>

        <div class="grid-4">
          <div class="coluna">
            <label for="dataAquisicao">Data Aquisição</label>
            <input type="date" id="dataAquisicao" v-model="dataAquisicao" />
          </div>
          <div class="coluna">
            <label for="unidade">Unidade</label>
            <select id="unidade" v-model="unidade">
              <option value="" disabled selected>Selecione</option>
              <option v-for="filial in filiais" :key="filial.id" :value="filial.id">{{ filial.local_nome }}</option>
            </select>
          </div>
          <div class="coluna">
            <label for="valor">Valor</label>
            <input type="text" id="valor" v-model="valor" @input="formatarValor" @blur="formatarValorBlur" autocomplete="off" />
          </div>
          <div class="coluna">
            <label for="subCategoria">Tipo *</label>
            <select id="subCategoria" v-model="subCategoria" required>
              <optgroup v-for="categoria in categorias" :key="categoria.id" :label="categoria.nome">
                <option v-for="sub in categoria.subCategoria" :key="sub.id" :value="sub.id">{{ sub.codigo }} - {{ sub.nome }}</option>
              </optgroup>
            </select>
          </div>
          <div class="coluna col-2">
            <label for="descricao">Descrição</label>
            <input type="text" id="descricao" v-model="descricao" autocomplete="off" />
          </div>
          <div class="coluna col-1">
            <label for="notaFiscal">Nota Fiscal</label>
            <input type="text" id="notaFiscal" v-model="notaFiscal" autocomplete="off" />
          </div>

          <div class="coluna col-1">
            <label for="dtFinalGarantia">Data Final da Garantia</label>
            <input type="date" id="dtFinalGarantia" v-model="dtFinalGarantia" autocomplete="off" />
          </div>
        </div>
      </div>
    </div>

    <div class="margem container">
      <div class="bloco margem">
        <div class="titulo"><h3>Características</h3></div>

        <div class="grid-4">
          <div class="">
            <label for="marca">Marca</label>
            <input type="text" id="marca" v-model="marca" autocomplete="off" />
          </div>
          <div class="">
            <label for="modelo">Modelo</label>
            <input type="text" id="modelo" v-model="modelo" autocomplete="off" />
          </div>
          <div class="">
            <label for="codigoBarras">Código de barras</label>
            <input type="text" id="codigoBarras" v-model="codigoBarras" autocomplete="off" />
          </div>
          <div class="">
            <label for="imei">IMEI</label>
            <input type="text" id="imei" v-model="imei" autocomplete="off" />
          </div>
          <div class="">
            <label for="numeroSerie">Nr. Série</label>
            <input type="text" id="numeroSerie" v-model="numeroSerie" autocomplete="off" />
          </div>
          <div class="coluna col-2">
            <label for="fornecedor">Fornecedor</label>
            <input type="text" id="fornecedor" v-model="fornecedorNome" @input="filtrarFornecedores" @focus="filtrarFornecedores" @blur="validarFornecedor" autocomplete="off" />
            <ul v-if="mostrarSugestoes && fornecedoresFiltrados.length" class="autocomplete">
              <li v-for="fornecedor in fornecedoresFiltrados" :key="fornecedor.id" @mousedown.prevent="selecionarFornecedor(fornecedor)">
                {{ fornecedor.nome }}
              </li>
            </ul>
          </div>
          <div class="">
            <label for="vidaUtil">Vida Útil</label>
            <input type="number" id="vidaUtil" v-model="vidaUtil" autocomplete="off" />
          </div>
          <div class="col-4">
            <label for="complemento">Complemento</label>
            <input type="text" id="complemento" v-model="complemento" autocomplete="off" />
          </div>
        </div>
      </div>
    </div>

    <div class="margem container">
      <div class="bloco margem">
        <div class="titulo"><h3>Anexos</h3></div>
        <input
          type="file"
          multiple
          @change="onFileChange"
          accept=".png, .jpg, .jpeg, .gif, .pdf, .doc, .docx, .xls, .xlsx, image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        />
        <ul>
          <br />
          <li v-for="(anexo, index) in anexos" :key="index">
            <a v-if="anexo.path" :href="getAnexoUrl(anexo.path)" target="_blank">{{ anexo.nome }}</a>
            <span v-else>{{ anexo.nome || anexo.name }}</span
            >&nbsp;
            <button @click="removerAnexo(index, anexo.id)" class="acao-secundaria">Excluir</button>
          </li>
        </ul>
      </div>
    </div>

    <div class="margem container">
      <div class="bloco margem">
        <div class="alinha-centro">
          <button style="margin-right: 10px" type="button" v-if="!modoCadastro" @click="cancelarAcao" class="acao-secundaria">Cancelar</button>
          <button type="button" @click="salvarAtivo" class="acao-secundaria">Salvar</button>
        </div>
      </div>
    </div>
  </form>

  <v-snackbar v-model="snackbarVisible" :color="snackbarColor" timeout="3000">
    {{ snackbarText }}
  </v-snackbar>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { getUnidade, getCategoria, getFornecedor, gravarAtivo, getBuscarAtivo, atualizarAtivo, excluirAnexo, adicionarAnexo } from "@/services/api";
import { sso } from "roboflex-thalamus-sso-lib";

export default {
  name: "CadastroAtivosView",
  data() {
    return {
      dataAquisicao: "",
      unidade: "",
      descricao: "",
      dtFinalGarantia: "",
      notaFiscal: "",
      valor: "",
      subCategoria: null,
      marca: "",
      modelo: "",
      codigoBarras: "",
      imei: "",
      complemento: "",
      numeroSerie: "",
      fornecedor: null,
      fornecedorNome: "",
      vidaUtil: "",
      imagem: "",
      categorias: [],
      filiais: [],
      fornecedores: [],
      fornecedoresFiltrados: [],
      mostrarSugestoes: false,
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "",
      usuario_id: null,
      modoCadastro: true,
      anexos: [],
      anexosExistentes: [],
    };
  },
  computed: {
    ...mapGetters(["getSelectedItemId"]),
  },
  watch: {
    getSelectedItemId(newVal) {
      if (newVal) {
        this.modoCadastro = false;
        this.carregarAtivo(newVal);
      } else {
        this.resetFormulario();
      }
    },
    fornecedores(newFornecedores) {
      if (this.fornecedor && !this.fornecedorNome) {
        const fornecedor = newFornecedores.find((f) => f.id === this.fornecedor);
        if (fornecedor) {
          this.fornecedorNome = fornecedor.nome;
        }
      }
    },
  },
  mounted() {
    this.buscarFiliais();
    this.buscarCategorias();
    this.buscarFornecedores();
    const id = this.getSelectedItemId;
    if (id) {
      this.modoCadastro = false;
      this.carregarAtivo(id);
    } else {
      this.resetFormulario();
    }
  },
  beforeRouteLeave(to, from, next) {
    this.resetFormulario();
    this.clearSelectedItem();
    next();
  },
  created() {
    const usuarioLogado = sso.getUsuarioLogado();
    this.usuario_id = usuarioLogado.id;
  },
  methods: {
    async removerAnexo(index, anexoId) {
      if (this.modoCadastro) {
        this.anexos.splice(index, 1);
      } else {
        try {
          await excluirAnexo({ anexo_id: anexoId });

          this.anexos.splice(index, 1);

          this.snackbarText = "Anexo excluído com sucesso!";
          this.snackbarColor = "success";
          this.snackbarVisible = true;
        } catch (error) {
          console.error("Erro ao excluir anexo:", error);

          this.snackbarText = "Erro ao excluir anexo.";
          this.snackbarColor = "error";
          this.snackbarVisible = true;
        }
      }
    },
    async onFileChange(event) {
      const arquivosPermitidos = [
        "image/png",
        "image/jpeg",
        "image/jpg",
        "image/gif",
        "application/pdf",
        "application/msword", // .doc
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
        "application/vnd.ms-excel", // .xls
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
      ];

      const novosAnexos = Array.from(event.target.files);

      let anexosValidos = [];

      novosAnexos.forEach((novoAnexo) => {
        if (!arquivosPermitidos.includes(novoAnexo.type)) {
          this.snackbarText = `O tipo de arquivo ${novoAnexo.name} não é permitido. Apenas imagens, PDFs, Word e Excel são aceitos.`;
          this.snackbarColor = "error";
          this.snackbarVisible = true;
        } else {
          const anexoExistente = this.anexosExistentes.find((anexo) => anexo.nome === novoAnexo.name);
          if (!anexoExistente) {
            anexosValidos.push(novoAnexo);
          }
        }
      });

      if (!this.modoCadastro && anexosValidos.length > 0) {
        for (const novoAnexo of anexosValidos) {
          const anexoFormData = new FormData();
          anexoFormData.append("ativo_id", this.getSelectedItemId);
          anexoFormData.append("anexos[]", novoAnexo);

          try {
            const response = await adicionarAnexo(anexoFormData, {
              headers: { "Content-Type": "multipart/form-data" },
            });

            const anexosSalvos = response.data.anexos_salvos;
            if (anexosSalvos && anexosSalvos.length > 0) {
              anexosSalvos.forEach((anexo) => {
                this.anexosExistentes.push(anexo);
              });
              this.anexos = [...this.anexosExistentes];
            }

            this.snackbarText = `Anexo ${novoAnexo.name} adicionado com sucesso!`;
            this.snackbarColor = "success";
            this.snackbarVisible = true;
          } catch (error) {
            console.error("Erro ao adicionar anexo:", error);
            this.snackbarText = `Falha ao adicionar o anexo ${novoAnexo.name}.`;
            this.snackbarColor = "error";
            this.snackbarVisible = true;
          }
        }
      } else {
        this.anexos = [...this.anexos, ...anexosValidos];
      }
    },
    getAnexoUrl(path) {
      const baseUrl = process.env.VUE_APP_ROOT_STORAGE || "";
      return `${baseUrl}${path}`;
    },
    ...mapMutations(["clearSelectedItem"]),
    resetFormulario() {
      this.dataAquisicao = "";
      this.unidade = "";
      this.descricao = "";
      this.dtFinalGarantia = "";
      this.notaFiscal = "";
      this.valor = "";
      this.subCategoria = null;
      this.marca = "";
      this.modelo = "";
      this.codigoBarras = "";
      this.imei = "";
      this.complemento = "";
      this.numeroSerie = "";
      this.fornecedor = "";
      this.fornecedorNome = "";
      this.vidaUtil = "";
      this.imagem = "";
      this.anexos = [];
      this.modoCadastro = true;
    },
    async validate() {
      let valid = true;
      //if (!this.unidade || !this.valor || !this.subCategoria) {
      if (!this.subCategoria) {
        valid = false;
        this.snackbarText = "Preencha todos os campos obrigatórios.";
        this.snackbarColor = "error";
        this.snackbarVisible = true;
      }
      return valid;
    },
    formatarValor(event) {
      const value = event.target.value.replace(/\D/g, "");
      const options = { style: "currency", currency: "BRL" };
      const formatted = new Intl.NumberFormat("pt-BR", options).format(value / 100);
      this.valor = formatted;
    },
    formatarValorBlur() {
      if (this.valor) {
        const value = this.valor.replace(/\D/g, "");
        const options = { style: "currency", currency: "BRL" };
        this.valor = new Intl.NumberFormat("pt-BR", options).format(value / 100);
      }
    },
    handleSubCategoriaChange(value) {
      const selected = this.categorias.flatMap((cat) => cat.subCategoria).find((sub) => sub.id === value);
      if (selected) {
        this.subCategoria = value;
      } else {
        this.subCategoria = null;
      }
    },
    async salvarAtivo() {
      const formValido = await this.validate();
      if (formValido) {
        const formData = new FormData();

        if (this.getSelectedItemId) formData.append("ativo_id", this.getSelectedItemId);
        if (this.dataAquisicao) formData.append("dtAquisicao", this.dataAquisicao);
        if (this.unidade) formData.append("unidade_id", parseInt(this.unidade));
        if (this.descricao) formData.append("descricao", this.descricao);
        if (this.dtFinalGarantia) formData.append("dtFinalGarantia", this.dtFinalGarantia);
        if (this.notaFiscal) formData.append("notaFiscal", this.notaFiscal);
        if (this.valor) {
          const valorFormatado = parseFloat(this.valor.replace("R$", "").replace(/\./g, "").replace(",", "."));
          formData.append("valor", valorFormatado);
        }
        if (this.subCategoria) formData.append("categoria_id", parseInt(this.subCategoria));
        if (this.marca) formData.append("marca", this.marca);
        if (this.modelo) formData.append("modelo", this.modelo);
        if (this.imei) formData.append("imei", this.imei);
        if (this.codigoBarras) formData.append("codigoBarras", this.codigoBarras);
        if (this.numeroSerie) formData.append("nrSerie", this.numeroSerie);
        if (this.fornecedor) formData.append("fornecedor_id", parseInt(this.fornecedor));
        if (this.vidaUtil) formData.append("vidaUtil", parseInt(this.vidaUtil));
        if (this.complemento) formData.append("complemento", this.complemento);
        formData.append("usuario_id", this.usuario_id);

        if (this.modoCadastro) {
          this.anexos.forEach((anexo, index) => {
            formData.append(`anexos[${index}]`, anexo);
          });
        }

        try {
          if (this.modoCadastro) {
            await gravarAtivo(formData, { headers: { "Content-Type": "multipart/form-data" } });
            this.$router.push({ name: "ListaAtivos", query: { message: "Ativo cadastrado com sucesso!", color: "success" } });
          } else {
            await atualizarAtivo(formData);
            this.$router.push({ name: "ListaAtivos", query: { message: "Ativo atualizado com sucesso!", color: "success" } });
          }
        } catch (error) {
          console.error("Erro ao salvar ativo:", error);
          this.snackbarText = "Erro ao salvar o ativo.";
          this.snackbarColor = "error";
          this.snackbarVisible = true;
        }
      }
    },

    async carregarAtivo(id) {
      try {
        const response = await getBuscarAtivo({ id });
        const ativo = response.data;

        this.dataAquisicao = ativo.dtAquisicao ? new Date(ativo.dtAquisicao).toISOString().split("T")[0] : "";
        this.unidade = ativo.unidade_id;
        this.descricao = ativo.descricao;
        this.dtFinalGarantia = ativo.dtFinalGarantia ? new Date(ativo.dtFinalGarantia).toISOString().split("T")[0] : "";
        this.notaFiscal = ativo.notaFiscal;
        this.valor = new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(ativo.valor);
        this.subCategoria = ativo.categoria_id;
        this.marca = ativo.marca;
        this.modelo = ativo.modelo;
        this.codigoBarras = ativo.codigoBarras;
        this.imei = ativo.imei;
        this.numeroSerie = ativo.nrSerie;
        this.fornecedor = ativo.fornecedor_id;
        this.vidaUtil = ativo.vidaUtil;
        this.complemento = ativo.complemento;

        const fornecedor = this.fornecedores.find((f) => f.id === ativo.fornecedor_id);
        if (fornecedor) {
          this.fornecedorNome = fornecedor.nome;
        }

        this.anexosExistentes = ativo.anexos || [];
        this.anexos = [...this.anexosExistentes];
      } catch (error) {
        console.error("Erro ao carregar ativo:", error);
      }
    },
    async buscarFiliais() {
      try {
        const response = await getUnidade();
        this.filiais = response.data;
      } catch (error) {
        console.error("Erro ao buscar filiais:", error);
      }
    },
    async buscarCategorias() {
      try {
        const response = await getCategoria();
        this.categorias = response.data;
      } catch (error) {
        console.error("Erro ao buscar categorias superiores:", error);
      }
    },
    async buscarFornecedores() {
      try {
        const response = await getFornecedor();
        this.fornecedores = response.data;
      } catch (error) {
        console.error("Erro ao buscar fornecedores:", error);
      }
    },
    validarFornecedor() {
      const fornecedorSelecionado = this.fornecedores.find((fornecedor) => fornecedor.nome.toLowerCase() === this.fornecedorNome.toLowerCase());
      if (!fornecedorSelecionado) {
        this.snackbarText = "Fornecedor inválido. Por favor, selecione um fornecedor da lista.";
        this.snackbarColor = "error";
        this.snackbarVisible = true;
        this.fornecedorNome = "";
        this.fornecedor = null;
        this.mostrarSugestoes = false;
      } else {
        this.fornecedor = fornecedorSelecionado.id;
      }
    },
    filtrarFornecedores() {
      const query = this.fornecedorNome.toLowerCase();
      this.fornecedoresFiltrados = this.fornecedores.filter((fornecedor) => fornecedor.nome.toLowerCase().includes(query));
      this.mostrarSugestoes = true;
    },

    selecionarFornecedor(fornecedor) {
      this.fornecedor = fornecedor.id;
      this.fornecedorNome = fornecedor.nome;
      this.mostrarSugestoes = false;
    },

    ocultarSugestoes() {
      setTimeout(() => {
        this.mostrarSugestoes = false;
      }, 200);
    },
    cancelarAcao() {
      this.resetFormulario();
      this.clearSelectedItem();
      this.$router.push({ name: "ListaAtivos" });
    },
  },
};
</script>

<style scoped>
.autocomplete {
  position: absolute;
  border: 1px solid #ccc;
  background-color: #fff;
  max-height: 150px;
  overflow-y: auto;
  z-index: 1000;
  width: 100%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 412px !important;
}

.autocomplete li {
  padding: 10px;
  cursor: pointer;
}

.autocomplete li:hover,
.autocomplete li.active {
  background-color: #f0f0f0;
}
</style>
